body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background: #1d1e2c;
  color: #008dd5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

button {
  display: inline-block;
  padding: 0px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
